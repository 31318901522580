import React, { useEffect, useState } from 'react'
import { Link, navigate } from 'gatsby'
// Components
import {
  ArqModal,
  BenefitsSwiper,
  EventLoginModal,
  FeaturedProducts,
  HeroSwiper,
  Loading,
  ProductCategory,
  Seo,
  ShoppingWithBanner,
} from '../components'
// Context
import { useAuthContext } from '../context/AuthContext'
import { useCartContext } from '../context/CartContext'
import { usePrismic } from '../context/PrismicContext'
import { useStoreContext } from '../context/StoreContext'
// Styles
import {
  CategoriesGrid,
  Section,
  ShopAllButton,
  Title,
  Wrapper,
} from '../styles/LandingPage.styles'
import { getUserEventStatus } from '../utils/eventSiteHelpers'

const LandingPage = () => {
  const {
    prismicData: {
      prismicHomepage: {
        event_slide_images,
        featured_products,
        happy_shopping,
        shop_all_products,
        shop_by_benefits,
        shop_type,
        slide_images,
        title,
      },
    },
  } = usePrismic()

  const {
    isAuthLoading,
    isEventSite,
    isVipSite,
    isVerified,
    qUser,
    handleUserLogout,
  } = useAuthContext()

  const { resetCartState } = useCartContext()

  const { landingPageData, isLandingPageLoading } = useStoreContext()

  const [arqModalOpen, setArqModalOpen] = useState(false)
  const [eventModalOpen, setEventModalOpen] = useState(false)

  const productTypes = landingPageData['type']?.children ?? []
  const productFeatured = landingPageData['featured-products'] ?? []

  // this will redirect existing Q members back to login & re-init the verification process
  useEffect(() => {
    if (qUser && !isVerified) {
      navigate('/login')
    }
  }, [isVerified])

  const shouldShowArqModal = localStorage.getItem('showArqModal')
  useEffect(() => {
    if (shouldShowArqModal === 'true') return setArqModalOpen(true)
  }, [shouldShowArqModal])

  const isEventOrVip = isEventSite || isVipSite

  useEffect(() => {
    if (isEventOrVip) {
      if (!qUser) {
        return
      }
      async function checkEventStatus() {
        const status = await getUserEventStatus(qUser?.legacyAssociateId)

        switch (status) {
          case 'vip':
            console.log('vip')
            // if (!isVipSite) {
            //   logoutShowModal()
            // }
            break
          case 'unite':
            console.log('unite')
            if (!isEventSite) {
              logoutShowModal()
            }
            break

          default:
            console.log('no status')
            logoutShowModal()
            break
        }
      }
      checkEventStatus()
    }
  }, [isEventSite, isVipSite, qUser])

  const logoutShowModal = () => {
    handleUserLogout()
    resetCartState()
    setEventModalOpen(true)
  }

  if (isLandingPageLoading || isAuthLoading)
    return <Loading loading={true} message={happy_shopping[0].text} />

  return (
    <>
      <Seo title={title[0].text} />
      <EventLoginModal
        open={eventModalOpen}
        handleCloseModal={() => setEventModalOpen(false)}
      />
      <Wrapper>
        <ShoppingWithBanner />
        <Section black>
          <HeroSwiper
            data-qa="heroBanner"
            slide_images={isEventOrVip ? event_slide_images : slide_images}
          />
        </Section>
        <Section>
          <Title data-qa="featuredProductsTitle">
            {featured_products[0].text}
          </Title>
          <FeaturedProducts products={productFeatured} />

          <ShopAllButton data-qa="shopAllButton" as={Link} to="/products">
            {shop_all_products[0].text}
          </ShopAllButton>
        </Section>
        <Section black={!isVipSite} isVip={isVipSite}>
          <Title white>{shop_by_benefits[0].text}</Title>
          <BenefitsSwiper />
        </Section>
        <Section>
          <Title>{shop_type[0].text}</Title>
        </Section>
        <CategoriesGrid style={{ marginBottom: '40px' }}>
          {productTypes.map(productType => (
            <ProductCategory key={productType.url_key} {...productType} />
          ))}
        </CategoriesGrid>
        <ArqModal
          open={arqModalOpen}
          onClose={() => {
            setArqModalOpen(false)
            localStorage.setItem('showArqModal', 'false')
          }}
        />
      </Wrapper>
    </>
  )
}

export default LandingPage
